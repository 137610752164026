import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import styled from 'styled-components';
import { theme } from '../utils/theme';
import { ArrowForward } from '@material-ui/icons';

const LayoutGrid = styled.div`
  @media (min-width: 900px) {
    display: grid;
  }
  img {
    border-radius: 5px;
  }
`;

const Grid = styled.div`
  @media (min-width: 900px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 32px;
  }
`;

const BlogCardWrapper = styled.div`
  p {
    color: #666;
    font-size: 16px;
    margin-bottom: 8px;
  }
`;

const ReadMe = styled.div`
  display: flex;
  color: ${theme.colors.accent};
  align-items: center;
  font-size: 16px;
  transition: 0.2s;
  svg {
    transition: 0.2s;
    transform: translateX(0);
  }

  :hover {
    svg {
      transition: 0.2s;
      transform: translateX(10px);
    }
  }
`;
export const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  const posts = data.allMarkdownRemark.edges;
  return (
    <Layout pageName="home" location={location} title={siteTitle} mw={32}>
      <SEO title="All posts" />
      <LayoutGrid>
        <Grid>
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug;
            return (
              <div key={node.fields.slug}>
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  <BlogCardWrapper>
                    <h3
                      style={{
                        marginTop: '14px',
                        marginBottom: '12px',
                        fontSize: '24px',
                        color: '#333',
                        fontWeight: '750',
                      }}
                    >
                      {title}
                    </h3>
                    <p>{node.frontmatter.description}</p>
                    <ReadMe>
                      Read more
                      <ArrowForward fontSize="small" />
                    </ReadMe>
                  </BlogCardWrapper>
                </Link>
              </div>
            );
          })}
        </Grid>
      </LayoutGrid>
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            imgSrc {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date
            description
          }
        }
      }
    }
  }
`;
